<!-- eslint-disable -->
<template>
  <app-module-view>
    <template slot="body">
      <app-kpi-nmh-settings-filter></app-kpi-nmh-settings-filter>
      <template v-if="kpiSettings.id">
        <table class="table">
          <template v-for="(statsGroup, groupName) in kpiSettings">
            <tbody
              v-if="groupName !== 'id' && ['adam', 'nmhVideo', 'pluskaHp', 'pluskaUmbrella'].includes(groupName) === false  && statsGroup"
              :key="groupName">
            <tr>
              <th :rowspan="Object.keys(statsGroup).length + 1">{{ groupLabel(groupName) }}</th>
            </tr>
            <tr v-for="(stats, type) in statsGroup" :key="groupName + '_' + type" v-if="shouldDisplay(type, groupName)">
              <th>{{ $t('kpiNmh.' + type) }}</th>
              <td>
                <app-input
                  v-model="stats.budget"
                  :id="`${groupName}_${type}_budget`"
                  :label="$t('kpiNmh.budget')"
                  type="number"
                  :min="0"
                  class="m-b-0"
                ></app-input>
              </td>
              <td>
                <app-input
                  v-model="stats.nmhAmb"
                  :id="`${groupName}_${type}_nmh_amb`"
                  :label="$t('kpiNmh.nmh_amb')"
                  type="number"
                  :min="0"
                  class="m-b-0"
                ></app-input>
              </td>
            </tr>
            <tr>
              <th colspan="5">&nbsp;</th>
            </tr>
            </tbody>
          </template>
        </table>
        <section class="toolbar-bottom text-right">
          <button
            v-if="isSaveButtonVisible"
            type="button"
            class="btn btn-success"
            @click="save"
          >
            <i class="fa fa-save"></i>
            <span class="hidden-sm-down">{{ $t('buttons.save') }}</span>
          </button>
        </section>
      </template>
    </template>
  </app-module-view>
</template>

<script>
/* eslint-disable */
import appModuleView from '../ModuleView'
import appKpiNmhSettingsFilter from './KpiNmhSettingsFilter'
import appInput from '../form/inputs/Input'
import { GROUP_LABEL } from '../../model/ValueObject/kpiNmhGroups'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'KpiNmhSettings',
  methods: {
    groupLabel (group) {
      return GROUP_LABEL[group] || group
    },
    save () {
      this.$store.dispatch('kpiNmh/updateSettingsItem', this.kpiSettings)
    },
    shouldDisplay (type, key) {
      return (type === 'videoViews' || type === 'realUsers' && key === 'nmhContent') === false
    }
  },
  computed: {
    isSaveButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.EDITOR_PERFORMANCE_KPI_NMH_SETTINGS_PERMISSIONS.saveButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    list () {
      return this.$store.getters['kpiNmh/list']
    },
    kpiSettings () {
      return this.$store.getters['kpiNmh/settingsItem']
    }
  },
  components: {
    appModuleView,
    appKpiNmhSettingsFilter,
    appInput
  }
}
</script>

<style lang="scss" scoped>
.table th {
  vertical-align: middle;
}

.toolbar-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px 10px 20px;
  background: #ffffff;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.mini-sidebar {
  .toolbar-bottom {
    padding-left: 80px;
  }
}

@media (min-width: 768px) {
  .toolbar-bottom {
    padding-left: 80px;
  }
}

@media (min-width: 1169px) {
  .toolbar-bottom {
    padding-left: 260px;
  }
}
</style>
