export const GROUP_BRAND_MAP = {
  'NMH content': [3, 5, 7, 8, 9, 10, 11, 12, 14, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27],
  'NMH video': [3, 5, 7, 8, 9, 10, 11, 12, 14, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27],
  News: [7, 14, 15, 20, 21, 26],
  Women: [5, 8, 10, 19, 22, 24, 25],
  Hobby: [1, 3, 9, 11, 12, 16, 23, 27],
  'Pluska umbrella': [13],
  'Pluska HP': [64],
  'Centrum HP': [6],
  'Plus JEDEN DEŇ': [14],
  'Plus 7 dní': [15],
  Trend: [7],
  'Šport 24': [20],
  Adam: [1],
  Autobild: [3],
  Automix: [4],
  'Čas pre ženy': [5],
  Emma: [8],
  'Dobré Jedlo': [9],
  Eva: [10],
  'Lepšie bývanie': [11],
  Magazín: [12],
  'Pekné bývanie': [16],
  Šarm: [19],
  'Új Szó': [21],
  Wanda: [22],
  Záhradkár: [23],
  Zdravie: [24],
  'Báječná žena': [25],
  Život: [26],
  'Poľovníctvo a rybárstvo': [27],
  'Centrum freemail': [28],
  Atlas: [2],
  'Plus TV': [17],
  Referaty: [18]
}

export const GROUP_LABEL = {
  nmhContent: 'NMH content',
  nmhVideo: 'NMH video',
  news: 'News',
  women: 'Women',
  hobby: 'Hobby',
  pluskaUmbrella: 'Pluska umbrella',
  pluskaHp: 'Pluska HP',
  centrumHp: 'Centrum HP',
  centrumFreemail: 'Centrum freemail',
  p1d: 'Plus JEDEN DEŇ',
  p7d: 'Plus 7 dní',
  trend: 'Trend',
  ujszo: 'Új Szó',
  sport24: 'Šport 24',
  zivot: 'Život',
  ncpz: 'Čas pre ženy',
  zena: 'Báječná žena',
  sarm: 'Šarm',
  emma: 'Emma',
  eva: 'Eva',
  wanda: 'Wanda',
  zdravie: 'Zdravie',
  autobild: 'Autobild',
  lepsiebyvanie: 'Lepšie bývanie',
  zahradkar: 'Záhradkár',
  polovnictvorybarstvo: 'Poľovníctvo a rybárstvo',
  dobrejedlo: 'Dobré Jedlo',
  magazin: 'Magazín'
}
